import React from 'react';
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import MainLeftColumn from "../../components/MainLeftColumn";
import MainRightColumn from "../../components/MainRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en"
);

const Index = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Blue River Mountains | Google Analytics & SEO Consulting"
          lang="no"
          description="Konsulenttjenester for SEO og Web Analytics på frilansbasis. Lær om SEO eller Google Analytics i våre veiledninger eller kontakt oss for hjelp med mer avanserte problemer."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/no"
        />
        <MainFrontpage>
          {/* Since MainLeftColumn and MainRightColumn need the language, they can consume the context within themselves. */}
          <MainLeftColumn />
          <MainRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Index);
